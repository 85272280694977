import { db } from '../../firebaseConfig';
import { getDocs, collection, query, where, orderBy, limit, limitToLast, startAfter, endBefore } from 'firebase/firestore/lite';


export const getDocument = async (collectionName, uniqueKey, searchValue) => {
  const collectionRef = collection(db, collectionName);
  const q = query(collectionRef,
    where(uniqueKey,'==',searchValue));

  try {
    const docs = await getDocs(q);
    return docs;
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }
};

export const listDocuments = async (collectionName, sortKey, returnKey, sortDirection) => {
  const collectionRef = collection(db, collectionName);
  const q =  query(collectionRef, orderBy(sortKey, sortDirection));

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }
  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      const data = doc.data();
      tempList.push({[returnKey]:data[returnKey]});
    }
  });
  return tempList;
};

export const listDocumentsWithPagination = async (collectionName, searchLimit, sortKey, sortDirection) => {
  const collectionRef = collection(db, collectionName);
  const q =  query(collectionRef,
    limit(searchLimit),
    orderBy(sortKey, sortDirection));

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }
  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push({docId: doc.id, ...doc.data()});
    }
  });

  if (docsSnapshot.docs.length < 1) {
    return;
  }

  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];
  let isNextAvailable;

  const nextq =  query(collectionRef,
    limit(searchLimit),
    orderBy(sortKey, sortDirection),
    startAfter(lastVisibleDocument));

  let docsSnapshotNextAhead;
  try {
    docsSnapshotNextAhead = await getDocs(nextq);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }
  if (docsSnapshotNextAhead.docs.length < 1) {
    isNextAvailable = false;
  } else {
    isNextAvailable = true;
  }

  return ({
    docs: tempList,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isNextAvailable: isNextAvailable
  })
};

export const listDocumentsWithPaginationNext = async (collectionName, lastVisible, searchLimit, sortKey, sortDirection) => {
  const collectionRef = collection(db, collectionName);
  const q =  query(collectionRef,
    limit(searchLimit),
    orderBy(sortKey, sortDirection),
    startAfter(lastVisible));

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }

  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push({docId: doc.id, ...doc.data()});
    }
  });

  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];
  let isNextAvailable;

  const nextq =  query(collectionRef,
    limit(searchLimit),
    orderBy(sortKey, sortDirection,
      startAfter(lastVisibleDocument)));

  let docsSnapshotNextAhead;
  try {
    docsSnapshotNextAhead = await getDocs(nextq);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }
  if (docsSnapshotNextAhead.docs.length < 1) {
    isNextAvailable = false;
  } else {
    isNextAvailable = true;
  }

  return ({
    docs: tempList,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isNextAvailable: isNextAvailable
  })
};

export const listDocumentsWithPaginationPrev = async (collectionName, firstVisible, searchLimit, sortKey, sortDirection) => {
  const collectionRef = collection(db, collectionName);
  const q =  query(collectionRef,
    limitToLast(searchLimit),
    orderBy(sortKey, sortDirection),
    endBefore(firstVisible));

  let docsSnapshot;
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }

  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push({docId: doc.id, ...doc.data()});
    }
  });

  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];
  let isPrevAvailable;

  const prevq =  query(collectionRef,
    limitToLast(searchLimit),
    orderBy(sortKey, sortDirection),
    endBefore(firstVisibleDocument));

  let docsSnapshotPrevBefore;
  try {
    docsSnapshotPrevBefore = await getDocs(prevq);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }

  if (docsSnapshotPrevBefore.docs.length < 1) {
    isPrevAvailable = false;
  } else {
    isPrevAvailable = true;
  }

  return ({
    docs: tempList,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isPrevAvailable: isPrevAvailable
  })
};
