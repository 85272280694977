import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';

const SignOut = () => {

  useEffect(() => {
    firebase.auth().signOut();
  }, []);

  return (
    <div>
      <center>
        <br></br>
        <h1>Logged Out!</h1>
        <Link to="/">Go Back to Login Screen</Link>
      </center>
    </div>
  );
}


export default SignOut;
