import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./components/context/AuthProvider.js";
import App from "./App.js";
import "./index.css";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import ManageUsers from "./pages/Admin/ManageUsers";
import CreateUser from "./pages/Admin/CreateUser.js";
import SignOut from "./pages/SignOut.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import UserProfile from "./pages/User/UserProfile.js";
import ManageBroadcasts from "./pages/Admin/ManageBroadcasts.js";
import ManageAdmins from "./pages/SuperAdmin/ManageAdmins.js";
import UserHelp from "./pages/User/UserHelp.js";
import AdminHelp from "./pages/Admin/AdminHelp.js";
import SuperAdminHelp from "./pages/SuperAdmin/SuperAdminHelp.js";
import ProjectCreate from "./pages/Budget/ProjectCreate.js";
import ProjectEdit from "./pages/Budget/ProjectEdit.js";
import ProjectSearch from "./pages/Budget/ProjectSearch.js";
import AllocGroupEdit from "./pages/Budget/AllocGroupEdit.js";
// import FirstAdmin from "./pages/SuperAdmin/FirstSetupAdmin.js";

ReactDOM.render(
    <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<Home />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/userhelp" element={<UserHelp />} />
        <Route path="/manageadmins" element={<ManageAdmins />} />
        <Route path="/manageusers" element={<ManageUsers />} />
        <Route path="/adminhelp" element={<AdminHelp />} />
        <Route path="/superadminhelp" element={<SuperAdminHelp />} />
        <Route path="/createuser" element={<CreateUser />} />
        <Route path="/managebroadcasts" element={<ManageBroadcasts />} />
        <Route path="/projectcreate" element={<ProjectCreate />} />
        <Route path="/projectdetails/:fundCode" element={<ProjectEdit />} />
        <Route path="/projectsearch" element={<ProjectSearch />} />
        <Route path="/allocgroupdetails/:fundCode/:group" element={<AllocGroupEdit />} />
        <Route path="/signout" element={<SignOut />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
    </BrowserRouter>,
  document.getElementById("root")
);

// Only use once during initial deployment for first admin user.
// <Route path="/backdoor" element={<FirstAdmin />} />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
