import * as React from "react";
import MainHeader from "../../components/navigation/MainHeader";
import classes from "./AdminHelp.module.css";

const AdminHelp = () => {

  return (
    <div>
      <MainHeader />
      <center>
      <h4 style={{marginTop:20}}>Admin User Guide</h4>
      <ul className={classes.viewBox}>
        <li> <h6>This is the Admin User Guide, if you need help using regular application features,
        the Regular User Help Guide can be found in:</h6>
        Go to Menu {">"} User {">"} Help
        <br></br><br></br>
        </li>
        <li> <h6>All Admin users have access to regular user features.</h6>
        <br></br>
        </li>
        <li> <h6>How to reset your password:</h6>
        Go to Menu {">"} User {">"} User Profile
        Then click the "Reset My Password button"
        <br></br><br></br>
        </li>
        <li> <h6>What is the "Audit Uploads" button?</h6>
        This feature will allow you to review the users' selfie uploads and check their GPS location and the time they took it.
        <br></br><br></br>
        </li>
        <li> <h6>What is the "Create User" button?</h6>
        This allows you to create new users in the system. Display Username, Email and Password are required fields,
        all other fields are optional. Follow the convention for Display Username to be like this FirstName+Username.
        Also, spaces are not allowed in the Display Username.
        <br></br><br></br>
        </li>
        <li> <h6>What is the "Manage Users" button?</h6>
        This will allow you to edit the details of the user, you can also add leave credits. Leave credits are set every year.
        <br></br><br></br>
        </li>
        <li> <h6>What is the "Broadcasts" button?</h6>
        This is where you can add messages that can be seen by all users in the system when they go to thhe HOME screen.
        You can also edit or delete existing messages.
        <br></br><br></br>
        </li>
        <li> <h6>What is the "Manage Leaves" button?</h6>
        This is the page where you can approve or reject leaves. Rejection requires a rejection message so the user will
        understand the reason for the rejection of their leave request.
        <br></br><br></br>
        </li>
        <li> <h6>What is the difference between HR Review and Status in Leaves?</h6>
        HR Review is approval/rejection by the ADMIN user or HR user. Status is the final approval/rejection by the SUPER ADMIN
        user or Management user (Mai or Joe).
        <br></br><br></br>
        </li>
      </ul>
      </center>
      <br></br>
      <br></br>
    </div>
  );
}

export default AdminHelp;
