import MainHeader from '../../components/navigation/MainHeader';
import { useNavigate } from 'react-router-dom';
import classes from "./ManageUsers.module.css";
import { useEffect, useState } from 'react';
import CustomLoader from '../../components/ui-common/CustomLoader';
import DeleteUserModal from '../../components/admin/DeleteUserModal';
import EditUserModal from '../../components/admin/EditUserModal';
import Button from 'react-bootstrap/Button';
import { functions, firebaseObserver } from '../../firebaseConfig';
import wcmatch from 'wildcard-match';
import { httpsCallable } from 'firebase/functions';


const ManageUsers = () => {

  // User list state
  const [userList, setUserList] = useState([]);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // User list state
  const [origUserList, setOrigUserList] = useState([]);
  // Display Name input form listener
  const [displayName, setDisplayName] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    firebaseObserver.subscribe('authAdminClaim', adminClaim => {
      if (!adminClaim) {
        alert('Unauthorized access.');
        navigate('/');
      }
    });
    listAllUsers();
    return () => { firebaseObserver.unsubscribe('authAdminClaim'); }
  }, [navigate]);

  function displayNameListener(event) {
    setDisplayName(event.target.value);
    searchDisplayName(event.target.value);
  }

  async function listAllUsers() {
    const listFirebaseAuthUsers = httpsCallable(functions, 'listFirebaseAuthUsers');

    // Enable loader when request is being sent.
    setLoaderState(true);
    await listFirebaseAuthUsers({
      // https://firebase.google.com/docs/functions/callable-reference
      // firebase functions only support POST and require a body to be passed.
      admin: true,
      superadmin: false,
      operator: false
    }).then((response) => {
        // console.log(response);
        // Disable loader when results are in
        setLoaderState(false);
        if (response?.data?.errorInfo) {
          alert(response.data.errorInfo.message);
        } else {
          // console.log(response.data.result);
          // Action to trigger after success.
          setUserList(response.data);
          setOrigUserList(response.data);
        }
      })
      // Disable loader in error
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }

  // Sort files Ascending order
  function sortItemsAsc() {
    const myData = [].concat(userList)
    myData.sort((a, b) => a.displayName > b.displayName ? 1 : -1);
    setUserList(myData);
  }

  // Sort files Descending order
  function sortItemsDesc() {
    const myData = [].concat(userList)
    myData.sort((a, b) => b.displayName > a.displayName ? 1 : -1);
    setUserList(myData);
  }

  // Search DisplayName Dynamically
  function searchDisplayName(displayName) {
    const isMatch = wcmatch(`*${displayName}*`);
    const myData = [];
    origUserList.forEach((user) => {
      if (isMatch(user.displayName)) {
        myData.push(user);
      }
    });
    setUserList(myData);
  }

  function getDeleteConfirmation(isSuccess, displayName) {
    if (isSuccess){
      const arr = userList.filter(obj => obj.displayName !== displayName);
      setUserList(arr);
      alert(`Successfully deleted ${displayName}`);
    }
  }

  async function getEditConfirmation(isSuccess, displayName) {
    if (isSuccess){
      await listAllUsers();
      alert(`Successfully edited ${displayName}`);
    }
  }


  return (
    <CustomLoader
    isActive={loaderState}
    >
    <div className={classes.overlayWrapper}><MainHeader />
    <center>
      <h4 style={{marginTop:20}}>Manage Users</h4>
      <div style={{display:'inline-block'}}>
        <label htmlFor='displayName'>Display Username:</label> &nbsp;
        <input type='displayName' value={displayName} id='displayName' onChange={displayNameListener} />
        <br></br>
        <Button
          variant="primary"
          onClick={sortItemsAsc}
          style={{marginTop:10}}
          className={classes.buttonStyle}
        >
          Sort Up
        </Button> &nbsp;
        <Button
          variant="primary"
          onClick={sortItemsDesc}
          style={{marginTop:10}}
          className={classes.buttonStyle}
        >
          Sort Down
        </Button>
      </div>
      <br></br>
      <div className={classes.viewBox}>
        <ul id="screenshots" className={classes.tablestyle}>
        {
          userList.map((val, index) => (
            <div key={val.displayName}>
              <label>
              <li>
                {val.displayName}
              </li>
              &nbsp;&nbsp;
              <EditUserModal
                details={val}
                index={index}
                roles={val.roles}
                onEditConfirmation={getEditConfirmation}
              />
              &nbsp;&nbsp;
              </label>
              <DeleteUserModal
                uid={val.uid}
                displayName={val.displayName}
                email={val.email}
                onDeleteConfirmation={getDeleteConfirmation}
              />
            </div>
          ))
        }
        </ul>
      </div>
    </center>
    </div>
    </CustomLoader>
  );
};

export default ManageUsers;
