import * as React from "react";
import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ProjectEdit.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import RolesChecker from "../../utilities/RolesChecker";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Footer from "../../components/navigation/Footer";
import { getDocument } from "../../components/database/get";
import { updateDocument } from "../../components/database/update";
import InsertAllocGroupButtonModal from "./InsertAllocGroupButtonModal";


const ProjectEdit = (props) => {

  const params = useParams();

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  const [loaderState, setLoaderState] = useState(false);

  // Form inputs
  const fundCode = params.fundCode;
  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState("");
  const [leader, setLeader] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [extendedEndDate, setExtendedEndDate] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [comments, setComments] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [createDate, setCreateDate] = useState("");

  const [documentId, setDocumentId] = useState("");
  const [allocGroupList, setAllocGroupList] = useState([]);

  const navigate = useNavigate();
  // const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          // console.log(parsedUserRoles);
          // console.log(allowedRolesList);
          // if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
          //   alert('Unauthorized access.');
          //   navigate('/');
          // }
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    searchProject();
    searchAllocationGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function searchProject() {
    const projectResult = await getDocument("projects", "fundCode", fundCode);
    populateFields(projectResult.docs[0].data());
    setDocumentId(projectResult.docs[0].id);
  }

  async function searchAllocationGroups() {
    const groupResult = await getDocument("allocation_groups", "fundCode", fundCode);
    setAllocGroupList(groupResult.docs);
  }

  function populateFields(projectDocument) {
    setDescription(projectDocument.description);
    setLeader(projectDocument.leader);
    setStartDate(projectDocument.startDate);
    setEndDate(projectDocument.endDate);
    setExtendedEndDate(projectDocument.extendedEndDate ?? " ");
    setComments(projectDocument.comments);
    setCreatedBy(projectDocument.createdBy);
    setCreateDate(projectDocument.createDate.toDate().toLocaleString());
  }

  function descriptionListener(event) {
    setDescription(event.target.value);
  }
  function leaderListener(event) {
    setLeader(event.target.value);
  }
  function startDateListener(event) {
    setStartDate(event.target.value);
  }
  function endDateListener(event) {
    if (!startDate) {
      alert("Please set start date first.")
      return;
    }
    if (startDate > event.target.value) {
      alert("End date should be greater than start date.")
      return;
    }
    setEndDate(event.target.value);
  }
  function extendEndDateListener(event) {
    if (!startDate) {
      alert("Please set start date first.")
      return;
    }
    if (endDate > event.target.value) {
      alert("Extend end date should be greater than end date.")
      return;
    }
    setExtendedEndDate(event.target.value);
  }
  function totalCostListener(event) {
    setTotalCost(event.target.value);
  }
  function commentsListener(event) {
    setComments(event.target.value);
  }

  async function editProject(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    const projectData = {
      fundCode: fundCode,
      description: description ?? " ",
      leader: leader,
      startDate: startDate,
      endDate: endDate,
      extendedEndDate: extendedEndDate ?? " ",
      totalCost: totalCost ?? " ",
      comments: comments ?? " ",
    };
    setLoaderState(true);
    try {
      await updateDocument("projects", documentId, projectData, displayName);
      alert ("Successfully edited project.");
      setLoaderState(false);
    } catch (error) {
      setLoaderState(false);
    }
    setValidated(false);
  }

  async function getInsertConfirmation(isSuccess) {
    if (isSuccess){
      await searchAllocationGroups();
    }
  }


  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Project Details
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form noValidate validated={validated} onSubmit={editProject}>
        <Form.Group className="mb-3" controlId="editProject">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={fundCode}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="description">
            Desciption:
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Description about the project. (optional)"
            value={description}
            onChange={descriptionListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="project-leader">
            Project Leader:
          </InputGroup.Text>
          <Form.Control
            required
            type="text"
            placeholder="Project Leader Name"
            value={leader}
            onChange={leaderListener}
          />
          <Form.Control.Feedback type="invalid">
            Project Leader should not be blank.
          </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="start-date" style={{color:"#ece7e7"}}>
            Start Date:
          </InputGroup.Text>
          <Form.Control
            type="date"
            placeholder="Start Date"
            value={startDate}
            inputMode='none'
            onChange={startDateListener}
          />
          <InputGroup.Text id="end-date">
            End Date:
          </InputGroup.Text>
          <Form.Control
            type="date"
            placeholder="End Date"
            value={endDate}
            inputMode='none'
            onChange={endDateListener}
          />
          </InputGroup>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="extend-end-date">
            Extend End Date:
          </InputGroup.Text>
          <Form.Control
            type="date"
            placeholder="Extend End Date (optional)"
            value={extendedEndDate}
            inputMode='none'
            onChange={extendEndDateListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="total-cost">
            Total Cost:
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Project Total Cost. (optional)"
            value={totalCost}
            onChange={totalCostListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="comments">
            Comments:
          </InputGroup.Text>
          <Form.Control
            as="textarea"
            aria-label="With textarea"
            placeholder="Comments about the project. (optional)"
            value={comments}
            onChange={commentsListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="createdBy">
            Created By:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createdBy}
          />
          <InputGroup.Text id="createDate">
            Create Timestamp:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createDate}
          />
          </InputGroup>

          <br></br>
          <center>
            <Button
              type="submit"
              variant="success"
            >
              Save Changes
            </Button>
          </center>

          <Form.Label column="lg">Allocation Groups</Form.Label>
          &nbsp;
          <InsertAllocGroupButtonModal
            fundCode={fundCode}
            displayName={displayName}
            onInsertConfirmation={getInsertConfirmation}
          />
          <br></br>
          <br></br>

          <ListGroup>
          { allocGroupList.length > 0
            ? allocGroupList.map((row) => (
                <ListGroup.Item
                  key={`${row.data().fundCode}_${row.data().group}`}
                  action
                  onClick={() => navigate(`/allocgroupdetails/${fundCode}/${row.data().group}`)}
                >
                  <div className="ms-2 me-auto">
                    <b>Allocation Group Name:</b> {row.data().group}
                    &nbsp; <b>Controller</b>: {row.data().controller}
                  </div>
                </ListGroup.Item>
              ))
            : null
          }
          </ListGroup>

        </Form.Group>
        </Form>
      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default ProjectEdit;
