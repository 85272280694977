import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { insertDocument } from "../../components/database/insert";


const InsertAllocGroupButtonModal = (props) => {

  const fundCode = props.fundCode;
  const displayName = props.displayName;
  const [group, setGroup] = useState("");
  const [controller, setController] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function groupListener(event) {
    setGroup(event.target.value);
  }
  function controllerListener(event) {
    setController(event.target.value);
  }

  async function createNewGroup() {
    const groupData = {
      fundCode: fundCode,
      group: group,
      controller: controller ?? " "
    };
    console.log(groupData);
    try {
      await insertDocument("allocation_groups", "", groupData, false, true, displayName);
      props.onInsertConfirmation(true);
    } catch (error) {
      console.log(error);
      alert ("Error during group creation.")
    };
    setController("");
    setGroup("");
    setShow(false);
  }


  return (
    <React.Fragment>
      <Button
        variant="success"
        onClick={handleShow}
      >
          Create Group
      </Button>
      <Modal
        size="m"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Allocation Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="inputItem">

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="group">Group Name:</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Name of the group."
              value={group}
              onChange={groupListener}
            />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="controller">Controller:</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Name of the controller. (optional)"
              value={controller}
              onChange={controllerListener}
            />
            </InputGroup>

          </Form.Group>
          </Form>
          <center>
            <Button
              variant="primary"
              onClick={createNewGroup}
            >
              Create
            </Button>
          </center>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default InsertAllocGroupButtonModal

