import * as React from "react";
import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./AllocGroupEdit.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import RolesChecker from "../../utilities/RolesChecker";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Footer from "../../components/navigation/Footer";
import { getDocument } from "../../components/database/get";
import { updateDocument } from "../../components/database/update";
import InsertAllocGroupButtonModal from "./InsertAllocGroupButtonModal";
import { searchDocuments } from "../../components/database/search";


const AllocGroupEdit = (props) => {

  const params = useParams();

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  const [loaderState, setLoaderState] = useState(false);

  // Form inputs
  const fundCode = params.fundCode;
  const [group, setGroup] = useState(params.group);
  const [controller, setController] = useState("");

  const [documentId, setDocumentId] = useState("");
  const [allocGrpoup, setAllocGroup] = useState({});
  const [ledgerList, setLedgerList] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [createDate, setCreateDate] = useState("");

  const navigate = useNavigate();
  // const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          // console.log(parsedUserRoles);
          // console.log(allowedRolesList);
          // if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
          //   alert('Unauthorized access.');
          //   navigate('/');
          // }
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    searchAllocationGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  async function searchAllocationGroup() {
    const searchFilters = [];
    searchFilters.push({key: "fundCode", inputValue: fundCode});
    searchFilters.push({key: "group", inputValue: group});
    const groupResult = await searchDocuments("allocation_groups", searchFilters, 1, "fundCode", "asc");
    populateFields(groupResult.docs[0]);
    setAllocGroup(groupResult.docs[0]);
    setDocumentId(groupResult.docs[0].docId);
  }

  function populateFields(allocGroupDocument) {
    setGroup(allocGroupDocument.group);
    setController(allocGroupDocument.controller);
    setCreatedBy(allocGroupDocument.createdBy);
    setCreateDate(allocGroupDocument.createDate.toDate().toLocaleString());
  }

  function groupListener(event) {
    setGroup(event.target.value);
  }
  function controllerListener(event) {
    setController(event.target.value);
  }

  async function editGroupAllocation(event) {
    const groupAllocationData = {
      group: group,
      controller: controller ?? " ",
    };
    setLoaderState(true);
    try {
      await updateDocument("projects", documentId, groupAllocationData, displayName);
      alert ("Successfully edited project.");
      setLoaderState(false);
    } catch (error) {
      setLoaderState(false);
    }
  }

  async function getInsertConfirmation(isSuccess) {
    if (isSuccess){
      // await searchAllocationGroups();
    }
  }


  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Allocation Group Details
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form noValidate onSubmit={editGroupAllocation}>
        <Form.Group className="mb-3" controlId="editGroupAllocation">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={fundCode}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="group">
            Group:
          </InputGroup.Text>
          <Form.Control
            required
            type="text"
            placeholder="Group name"
            value={group}
            onChange={groupListener}
          />
          <Form.Control.Feedback type="invalid">
            Group should not be blank.
          </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="controller">
            Controller:
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Controller Name"
            value={controller}
            onChange={controllerListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="createdBy">
            Created By:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createdBy}
          />
          <InputGroup.Text id="createDate">
            Create Timestamp:
          </InputGroup.Text>
          <Form.Control
            disabled
            type="text"
            value={createDate}
          />
          </InputGroup>

          <br></br>
          <center>
            <Button type="submit">
              Save Changes
            </Button>
          </center>

          <Form.Label column="lg">Ledger</Form.Label>
          &nbsp;
          <InsertAllocGroupButtonModal
            fundCode={fundCode}
            displayName={displayName}
            onInsertConfirmation={getInsertConfirmation}
          />
          <br></br>
          <br></br>

          <ListGroup>
          { ledgerList.length > 0
            ? ledgerList.map((row) => (
                <ListGroup.Item
                  key={`${row.data().fundCode}_${row.data().group}`}
                  action
                  onClick={() => navigate(`/allocgroupdetails/${fundCode}/${row.data().group}`)}
                >
                  <div className="ms-2 me-auto">
                    <b>Allocation Group Name:</b> {row.data().group}
                    &nbsp; <b>Controller</b>: {row.data().controller}
                  </div>
                </ListGroup.Item>
              ))
            : null
          }
          </ListGroup>

        </Form.Group>
        </Form>
      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default AllocGroupEdit;
