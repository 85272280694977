import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore/lite';
import ReactObserver from 'react-event-observer';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";

// Firebase config - DEV
export const firebaseConfig = {
  apiKey: "AIzaSyDCpiIo6R7Yo1t-R73CXSgJLTJZw0kNZ6k",
  authDomain: "nextjs-portal-template.firebaseapp.com",
  projectId: "nextjs-portal-template",
  storageBucket: "nextjs-portal-template.appspot.com",
  messagingSenderId: "526423364553",
  appId: "1:526423364553:web:54b4fce4bf23dae9468e18",
  measurementId: "G-ZP36Q4360W"
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, process.env.REACT_APP_FIREBASE_REGION);
export const analytics = getAnalytics(app);

export const firebaseObserver = ReactObserver();
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    user.getIdTokenResult().then(function(data) {
      firebaseObserver.publish("userAuthContext",
        {
          displayName: user.displayName,
          // roles: data.claims?.roles,
        }
      );
    });
  } else {
    firebaseObserver.publish("userAuthContext",
      null
    );
  }
});

// use ReactObserver instead
// export async function isAuth() {
//   await firebase.auth().onAuthStateChanged(function(user) {
//     if (user) {
//       return true;
//     } else {
//       return false;
//     }
//   });
// }

// export async function isAdmin() {
//   await firebase.auth().onAuthStateChanged(function(user) {
//     if (user) {
//       user.getIdTokenResult().then(function(data) {
//         return data.claims?.admin;
//       });
//     } else {
//       return false;
//     }
//   });
// }

// export async function isSuperAdmin() {
//   await firebase.auth().onAuthStateChanged(function(user) {
//     if (user) {
//       user.getIdTokenResult().then(function(data) {
//         return data.claims?.superadmin;
//       });
//     } else {
//       return false;
//     }
//   });
// }
