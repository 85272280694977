import * as React from "react";
import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ProjectSearch.module.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import RolesChecker from "../../utilities/RolesChecker";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Footer from "../../components/navigation/Footer";
import { searchDocuments } from "../../components/database/search";


const ProjectSearch = (props) => {

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  const [loaderState, setLoaderState] = useState(false);

  // Form inputs
  const [fundCode, setFundCode] = useState("");
  const [leader, setLeader] = useState("");
  const [startDateRange, setStartDateRange] = useState("");
  const [endDateRange, setEndDateRange] = useState("");

  // Results
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();
  // const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          // console.log(parsedUserRoles);
          // console.log(allowedRolesList);
          // if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
          //   alert('Unauthorized access.');
          //   navigate('/');
          // }
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function fundCodeListener(event) {
    setFundCode(event.target.value);
  }
  function leaderListener(event) {
    setLeader(event.target.value);
  }
  function startDateRangeListener(event) {
    setStartDateRange(event.target.value);
  }
  function endDateRangeListener(event) {
    if (!startDateRange) {
      alert("Please set start date first.")
      return;
    }
    if (startDateRange > event.target.value) {
      alert("End date should be greater than start date.")
      return;
    }
    setEndDateRange(event.target.value);
  }

  async function searchProject() {
    if ((startDateRange && !endDateRange) ||
      (!startDateRange && endDateRange)
    ) {
      alert ("When searching for Start Date, Start Date Range and End Date Range must not be empty.");
    }
    setLoaderState(true);
    try {
      const searchResults = await searchDocuments("projects", [{key: "fundCode", inputValue: fundCode}], 10, "startDate", "asc");
      console.log(searchResults.docs);
      setSearchResults(searchResults.docs);
      setLoaderState(false);
      // navigate(`/projectdetails/${fundCode}`);
    } catch (error) {
      setLoaderState(false);
    }
  }

  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Search Project
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form noValidate>
        <Form.Group className="mb-3" controlId="searchProject">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            required
            type="text"
            placeholder="Fund Code"
            value={fundCode}
            onChange={fundCodeListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="project-leader">
            Project Leader:
          </InputGroup.Text>
          <Form.Control
            required
            type="text"
            placeholder="Project Leader Name"
            value={leader}
            onChange={leaderListener}
          />
          </InputGroup>

          <Form.Label column="mb">Start Date</Form.Label>
          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="start-date-range">
            Start Date Range:
          </InputGroup.Text>
          <Form.Control
            required
            type="date"
            placeholder="Start Date"
            value={startDateRange}
            inputMode='none'
            onChange={startDateRangeListener}
          />
          <InputGroup.Text id="end-date-range">
            End Date Range:
          </InputGroup.Text>
          <Form.Control
            required
            type="date"
            placeholder="End Date"
            value={endDateRange}
            inputMode='none'
            onChange={endDateRangeListener}
          />
          </InputGroup>
        </Form.Group>
        </Form>
        <center>
          <Button
            variant="success"
            onClick={searchProject}
          >
            Search Project
          </Button>
        </center>
        <br></br>
        <ListGroup>
        { searchResults.length > 0
          ? searchResults.map((row) => (
              <ListGroup.Item key={row.fundCode} action onClick={() => navigate(`/projectdetails/${fundCode}`)}>
                <div className="ms-2 me-auto">
                  <b>Fund Code:</b> {row.fundCode}
                  &nbsp; <b>Leader</b>: {row.leader}
                  &nbsp; <b>Start</b>: {row.startDate}
                  &nbsp; <b>End</b>: {row.endDate}
                </div>
              </ListGroup.Item>
            ))
          : null
        }
        </ListGroup>
      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default ProjectSearch;
