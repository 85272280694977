// Import FirebaseAuth and firebase.
import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import { firebaseConfig, auth } from '../firebaseConfig';
import { setPersistence, browserLocalPersistence } from "firebase/auth";
import { useNavigate } from "react-router-dom";

firebase.initializeApp(firebaseConfig);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/home',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      disableSignUp: {
        status:true,
        adminEmail:'techjumpstartup@gmail.com',
        helpLink:"www.yahoo.com"
      }
    },

    {
      provider: firebase.auth.PhoneAuthProvider.PHONE_SIGN_IN_METHOD,
      disableSignUp: {
        status: true,
        adminEmail:'support@techjumpstartup.com',
        helpLink:"www.techjumpstartup.com"
      }
    }
  ],
};


const SignIn = () => {
  console.log(firebaseConfig);
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  // set auth state persistence, at session level
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      sessionStorage.setItem("lastname", "Smith");
    })
    .catch((error) => {
      // Handle Errors here.
  });

  const navigate = useNavigate()

  if (!isSignedIn) {
    return (
      <div>
        <br></br>
        <h1>Budget Managment System</h1>
        <br></br>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    );
  }
  return (
    <div>
      { navigate('/home') }
    </div>
  );
}

export default SignIn;
