import * as React from "react";
import MainHeader from "../../components/navigation/MainHeader";
import classes from "./UserHelp.module.css";

const UserHelp = () => {

  return (
    <div>
      <MainHeader />
      <center>
      <h4 style={{marginTop:20}}>Regular User Guide</h4>
      <ul className={classes.viewBox}>
        <li> <h6>How to reset your password:</h6>
        Go to Menu {">"} User {">"} User Profile
        Then click the "Reset My Password button"
        <br></br><br></br>
        </li>
      </ul>
      </center>
      <br></br>
      <br></br>
    </div>
  );
}

export default UserHelp;
