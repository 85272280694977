import { db } from '../../firebaseConfig';
import { collection, getDocs, doc, setDoc, query, where, Timestamp } from 'firebase/firestore/lite';


export const insertDocument = async (collectionName, uniqueKey, insertData, isDocIdUniqueKey, uniqueCheck, contextUser) => {

  if (insertData[uniqueKey] === "BLANK") {
    alert('BLANK is a system reserved word, do not use it as a record unique key.')
    return false;
  }

  const collectionRef = collection(db, collectionName);

  let docsSnapshot;
  if (!uniqueCheck) {
    try {
      const q = query(collectionRef,
        where(uniqueKey,'==', insertData[uniqueKey]));
      docsSnapshot = await getDocs(q);
    } catch (error) {
      alert (`DB error encountered: ${error}`);
      return false;
    }

    if (docsSnapshot.docs.length > 0) {
      alert('Record already exists, please use a different name or edit the existing one.')
      return false;
    }
  }
  insertData.createdBy = contextUser;
  insertData.createDate = Timestamp.now();

  try {
    if (isDocIdUniqueKey) {
      await setDoc(doc(collectionRef, insertData[uniqueKey]), insertData);
    } else {
      await setDoc(doc(collectionRef), insertData);
    }
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return false;
  }

  return true;
};
