import * as React from "react";
import { useState, useEffect } from "react";
import MainHeader from "../../components/navigation/MainHeader";
import CustomLoader from '../../components/ui-common/CustomLoader';
import classes from "./ProjectCreate.module.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../components/context/useAuthContext";
import firebase from 'firebase/compat/app';
import RolesChecker from "../../utilities/RolesChecker";
import { insertDocument } from "../../components/database/insert";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Footer from "../../components/navigation/Footer";


const ProjectCreate = (props) => {

  const { user } = useAuthContext();
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);
  const [loaderState, setLoaderState] = useState(false);

  // Form inputs
  const [validated, setValidated] = useState(false);
  const [fundCode, setFundCode] = useState("");
  const [description, setDescription] = useState("");
  const [leader, setLeader] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [comments, setComments] = useState("");

  const navigate = useNavigate();
  // const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("use effect triggered");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          // console.log(parsedUserRoles);
          // console.log(allowedRolesList);
          // if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
          //   alert('Unauthorized access.');
          //   navigate('/');
          // }
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function fundCodeListener(event) {
    setFundCode(event.target.value);
  }
  function descriptionListener(event) {
    setDescription(event.target.value);
  }
  function leaderListener(event) {
    setLeader(event.target.value);
  }
  function startDateListener(event) {
    setStartDate(event.target.value);
  }
  function endDateListener(event) {
    if (!startDate) {
      alert("Please set start date first.")
      return;
    }
    if (startDate > event.target.value) {
      alert("End date should be greater than start date.")
      return;
    }
    setEndDate(event.target.value);
  }
  function totalCostListener(event) {
    setTotalCost(event.target.value);
  }
  function commentsListener(event) {
    setComments(event.target.value);
  }

  async function createProject(event) {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      return;
    }
    const projectData = {
      fundCode: fundCode,
      description: description ?? " ",
      leader: leader,
      startDate: startDate,
      endDate: endDate,
      totalCost: totalCost ?? " ",
      comments: comments ?? " ",
    };
    setLoaderState(true);
    try {
      const success = await insertDocument("projects", "fundCode", projectData, false, false, displayName);
      setLoaderState(false);
      if (success) {
        navigate(`/projectdetails/${fundCode}`);
      }
    } catch (error) {
      setLoaderState(false);
    }
  }

  return (
    <CustomLoader
      isActive={loaderState}
      styles={{maxHeight:'100%'}}
    >
    <div className={classes.overlayWrapper}>
      <MainHeader
        name={displayName}
        roles={roles}
      />
      <center>
        <h3 style={{marginTop:15}}>
          Create New Project
        </h3>
      </center>
      <div className={classes.mainInputContainer}>
        <Form noValidate validated={validated} onSubmit={createProject}>
        <Form.Group className="mb-3" controlId="createProject">

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="fund-code">
            Fund Code:
          </InputGroup.Text>
          <Form.Control
            required
            type="text"
            placeholder="Fund Code"
            value={fundCode}
            onChange={fundCodeListener}
          />
          <Form.Control.Feedback type="invalid">
            Fund code should not be blank.
          </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="description">
            Desciption:
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Description about the project. (optional)"
            value={description}
            onChange={descriptionListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="project-leader">
            Project Leader:
          </InputGroup.Text>
          <Form.Control
            required
            type="text"
            placeholder="Project Leader Name"
            value={leader}
            onChange={leaderListener}
          />
          <Form.Control.Feedback type="invalid">
            Project Leader should not be blank.
          </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="start-date">
            Start Date:
          </InputGroup.Text>
          <Form.Control
            required
            type="date"
            placeholder="Start Date"
            value={startDate}
            inputMode='none'
            onChange={startDateListener}
          />
          <InputGroup.Text id="end-date">
            End Date:
          </InputGroup.Text>
          <Form.Control
            required
            type="date"
            placeholder="End Date"
            value={endDate}
            inputMode='none'
            onChange={endDateListener}
          />
          <Form.Control.Feedback type="invalid">
            Start and End date should not be blank.
          </Form.Control.Feedback>
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="total-cost">
            Total Cost:
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Project Total Cost. (optional)"
            value={totalCost}
            onChange={totalCostListener}
          />
          </InputGroup>

          <InputGroup className="mb-1" size="sm">
          <InputGroup.Text id="comments">
            Comments:
          </InputGroup.Text>
          <Form.Control
            as="textarea"
            aria-label="With textarea"
            placeholder="Comments about the project. (optional)"
            value={comments}
            onChange={commentsListener}
          />
          </InputGroup>
          <br></br>
          <center>
            <Button
              variant="success"
              type="submit"
            >
              Create Project
            </Button>
          </center>
        </Form.Group>
        </Form>
      </div>
      <Footer />
    </div>
    </CustomLoader>
  );
}

export default ProjectCreate;
