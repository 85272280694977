import { Fragment, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import classes from './SideBar.module.css';
import Badge from 'react-bootstrap/Badge';
import MenuLinks from './MenuLinks';


const SideBar = (props) => {
  // Sidebar open and close state
  const [show, setShow] = useState(false);
  // PO SubMenu open and close state
  const [projAdminMenuShow, setProjAdminMenuShow] = useState(false);
  // Items SubMenu open and close state
  const [itemsMenuShow, setItemsMenuShow] = useState(false);
  // Items SubMenu open and close state
  const [dataLoaderMenuShow, setdataLoaderMenuShow] = useState(false);
  // Supplier SubMenu open and close state
  const [supplierMenuShow, setSupplierMenuShow] = useState(false);
  // User SubMenu open and close state
  const [userSubMenuShow, setUserSubMenuShow] = useState(false);
  // Admin SubMenu open and close state
  const [adminSubMenuShow, setAdminSubMenuShow] = useState(false);
  // Super Admin SubMenu open and close state
  const [superAdminSubMenuShow, setSuperAdminSubMenuShow] = useState(false);

  const displayName = props.displayName;
  const roles = props.roles;

  function handleClose () {
    setShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function handleShow () {setShow(true)};

  function toggleSupplierMenuShow() {
    setSupplierMenuShow(!supplierMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setProjAdminMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleItemsMenuShow() {
    setItemsMenuShow(!itemsMenuShow);
    setdataLoaderMenuShow(false);
    setProjAdminMenuShow(false);
    setSupplierMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleDataLoaderMenuShow() {
    setdataLoaderMenuShow(!dataLoaderMenuShow);
    setItemsMenuShow(false);
    setProjAdminMenuShow(false);
    setSupplierMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleprojAdminMenuShow() {
    setProjAdminMenuShow(!projAdminMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setSupplierMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
  }
  function toggleUserSubMenu() {
    setUserSubMenuShow(!userSubMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setSupplierMenuShow(false);
    setAdminSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
    setProjAdminMenuShow(false);
  }
  function toggleAdminSubMenu() {
    setAdminSubMenuShow(!adminSubMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setSupplierMenuShow(false);
    setUserSubMenuShow(false);
    setSuperAdminSubMenuShow(false);
    setProjAdminMenuShow(false);
  }
  function toggleSuperAdminSubMenu() {
    setSuperAdminSubMenuShow(!superAdminSubMenuShow);
    setItemsMenuShow(false);
    setdataLoaderMenuShow(false);
    setUserSubMenuShow(false);
    setAdminSubMenuShow(false);
    setProjAdminMenuShow(false);
  }

  return (
    <Fragment>
      <Button variant="success" onClick={handleShow} size="lg" >
        MENU &nbsp; <i className="fa-solid fa-bars fa-xl"></i>
      </Button>
      <div style={{margin:10}}>
        { roles.length > 0
          ? roles.map((role) => (
              <Badge
                pill
                bg="info"
                text="dark"
                style={{marginRight: "4px"}}
              >
                {role}
              </Badge>
            ))
          : null
        }
        <Badge
          pill
          bg="warning"
          text="dark"
        >
          {displayName}
        </Badge>
      </div>
      <Offcanvas show={show} onHide={handleClose} style={
        {
          backgroundColor:"#064422",
          color: "whitesmoke",
          width: 200
        }
      }>
        <Offcanvas.Header closeButton closeVariant='white'>
          <Offcanvas.Title>MENU</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className={classes.linkList}>
            <li>
              <Link to='/home'>
                <Button variant="success">
                  Home
                </Button>
              </Link>
            </li>

            <li>
              { roles.find(role => role === "ADMIN")
                ? <Button variant="success" onClick={toggleprojAdminMenuShow}>
                    Project Admin
                  </Button>
                : null
              }
            </li>
            <MenuLinks
              collapse={projAdminMenuShow}
              objectLinksList={[
                {label:"Create Project", link:"/projectCreate"},
                {label:"Search and Edit Project", link:"/projectSearch"},
                {label:"Help", link:"/projecthelp"}
              ]}
            />

            <li>
              <Button variant="success" onClick={toggleUserSubMenu}>
                User
              </Button>
            </li>
            <MenuLinks
              collapse={userSubMenuShow}
              objectLinksList={[
                {label:"User Profile", link:"/userprofile"},
                {label:"Help", link:"/userhelp"}
              ]}
            />

            <li>
              { roles.find(role => role === "ADMIN")
                ? <Button variant="success" onClick={toggleAdminSubMenu}>
                    Admin
                  </Button>
                : null
              }
            </li>
            <MenuLinks
              collapse={adminSubMenuShow}
              objectLinksList={[
                {label:"Create User", link:"/createuser"},
                {label:"Manage Users", link:"/manageusers"},
                {label:"Broadcasts", link:"/managebroadcasts"},
                {label:"Help", link:"/adminhelp"}
              ]}
            />

            <li>
              { roles.find(role => role === "SUPERADMIN")
                ? <Button variant="success" onClick={toggleSuperAdminSubMenu}>
                    Super Admin
                  </Button>
                : null
              }
            </li>
            <MenuLinks
              collapse={superAdminSubMenuShow}
              objectLinksList={[
                {label:"Manage Admins", link:"/manageadmins"},
                {label:"Help", link:"/superadminhelp"}
              ]}
            />


            <li>
              <Link to='/signout'>
              <Button variant="success">
                Sign Out
              </Button>
            </Link>
            </li>

          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  )
}

export default SideBar;
