import { db } from '../../firebaseConfig';
import { getDocs, collection, query, where, orderBy, startAfter, limit } from 'firebase/firestore/lite';


export const searchDocuments = async (collectionName, searchFilters, searchLimit, sortOrderKey, sortOrderDirection) => {
  const collectionRef = collection(db, collectionName);
  let q;
  let orderByFilter;
  let isNextAvailable = false;

  if (!validateInputs(collectionName, searchFilters, searchLimit)) {
    return;
  }

  // Parse Inputs
  const queryFilters = searchFilters.map((searchFilter) => where(searchFilter.key,'==',searchFilter.inputValue));
  if (sortOrderKey) {
    orderByFilter = orderBy(sortOrderKey, "asc");
    if (sortOrderDirection) {
      orderByFilter = orderBy(sortOrderKey, sortOrderDirection);
    }
  }
  // Generate Query
  if (orderByFilter) {
    q = query(collectionRef,
      ...queryFilters,
      orderByFilter,
      limit(searchLimit));
  } else {
    q = query(collectionRef,
      ...queryFilters,
      limit(searchLimit));
  }
  // console.log(q);
  // Execute Query
  let docsSnapshot
  try {
    docsSnapshot = await getDocs(q);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    console.log(error);
    return;
  }

  const tempList = [];
  docsSnapshot.forEach((doc) => {
    if (doc) {
      tempList.push({docId: doc.id, ...doc.data()});
    }
  });

  const parsedDocs = tempList;
  const firstVisibleDocument = docsSnapshot.docs[0];
  const lastVisibleDocument = docsSnapshot.docs[docsSnapshot.docs.length-1];

  if (!lastVisibleDocument) {
    alert('No records found.')
    return;
  }
  // Check if next available results are possible
  let nextq;
  if (orderByFilter) {
    nextq = query(collectionRef,
      ...queryFilters,
      orderByFilter,
      startAfter(lastVisibleDocument),
      limit(searchLimit));
  } else {
    nextq = query(collectionRef,
      ...queryFilters,
      startAfter(lastVisibleDocument),
      limit(searchLimit));
  }

  let docsSnapshotNextAhead;
  try {
    docsSnapshotNextAhead = await getDocs(nextq);
  } catch (error) {
    alert (`DB error encountered: ${error}`);
    return;
  }

  if (docsSnapshotNextAhead.docs.length < 1) {
    isNextAvailable = false;
  } else {
    isNextAvailable = true;
  }

  return ({
    docs: parsedDocs,
    firstVisibleDocument: firstVisibleDocument,
    lastVisibleDocument: lastVisibleDocument,
    isNextAvailable: isNextAvailable
  })
};

function validateInputs(collectionName, searchFilters, searchLimit) {
  if (!collectionName || !searchFilters || !searchLimit) {
    alert('Incomplete database search parameters.');
    return false;
  }
  return true;
}
