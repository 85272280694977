import { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import AuthContext from './AuthContext';

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      setUser(user);
    });
  }, []);

  firebase.auth().onAuthStateChanged(function(user) {
    setUser(user);
  });

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );

};
