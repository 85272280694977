import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { auth, functions } from '../../firebaseConfig';
import { sendPasswordResetEmail } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { UserRolesList } from '../../models/user-roles';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import { X } from "react-bootstrap-icons";

const EditUserModal = (props) => {

  const [show, setShow] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);
  // Input States
  // firstName
  const [firstName, setFirstName] = useState(props.details.firstName ? props.details.firstName : '');
  // lastName
  const [lastName, setLastName] = useState(props.details.lastName ? props.details.lastName : '');
  // role
  const [roles, setRoles] = useState(props.roles);
  // phoneNumber
  const [phoneNumber, setPhoneNumber] = useState(props.details.phoneNumber ? props.details.phoneNumber : '');
  const [userRolesList, setUserRolesList] = useState(removeDuplicateRoles);
  const [validated, setValidated] = useState(false);


  function handleClose () {
    resetValues();
    setShow(false);
  };
  function handleShow () {
    setShow(true);
  };

  function resetValues() {
    setFirstName(props.details.firstName ? props.details.firstName : '');
    setLastName(props.details.lastName ? props.details.lastName : '');
    setPhoneNumber(props.details.phoneNumber ? props.details.phoneNumber : '');
    setRoles(props.roles);
    setUserRolesList(removeDuplicateRoles);
  }

  // Input Listeners
  function firstNameListener(event) {
    setFirstName(event.target.value);
  }
  function lastNameListener(event) {
    setLastName(event.target.value);
  }
  function phoneNumberListener(event) {
    setPhoneNumber(event.target.value);
  }

  function removeDuplicateRoles() {
    const tempRolesList = [].concat(UserRolesList);
    props.roles.forEach(function(role) {
      let deleteIndex = tempRolesList.indexOf(role);
      if (deleteIndex !== -1) {
        tempRolesList.splice(deleteIndex, 1);
      }
    });
    return tempRolesList;
  }

  function removeRoles(role) {
    const tempRolesList = [].concat(userRolesList);
    let deleteIndex = tempRolesList.indexOf(role);
    if (deleteIndex !== -1) {
      tempRolesList.splice(deleteIndex, 1);
    }
    setUserRolesList(tempRolesList);
  }

  async function saveHandler(event) {
    event.preventDefault();
    if (roles.length === 0) {
      alert("Please select a role for the user.");
      return;
    }
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }
    const authDetails = {
      phoneNumber: phoneNumber === '' ? null : phoneNumber,
    };
    const dbDetails = {
      displayName: props.details.displayName,
      uid: props.details.uid,
      email: props.details.email,
      firstName: firstName,
      lastName: lastName,
      roles: roles,
      phoneNumber: phoneNumber
    };
    await editUser(props.details.uid, authDetails, props.details.displayName, dbDetails);
    setShow(false);
  }

  function cancelHandler() {
    resetValues();
    setShow(false);
  }

  function addRoleHandler(role) {
    const tempRoles = [].concat(roles);
    tempRoles.push(role);
    removeRoles(role);
    setRoles(tempRoles);
  }

  function removeRoleHandler(index) {
    const tempRoles = [].concat(roles);
    tempRoles.splice(index, 1);
    setRoles(tempRoles);
    setUserRolesList(removeDuplicateRoles);
  }

  async function resetPassword(email) {
    setLoaderState(true);
    return await sendPasswordResetEmail(auth, email).then((a) => {
      setLoaderState(false);
      alert(`Password reset email sent to ${email}.`);
      setShow(false);
    });
  }

  async function editUser(uid, authDetails, displayName, dbDetails) {
    // console.log(dbDetails);
    setLoaderState(true);
    const editUser = httpsCallable(functions, 'editUser');
    await editUser({
      uid: uid,
      authDetails: authDetails,
      displayName: displayName,
      dbDetails: dbDetails,
    }).then((response) => {
        // Disable loader when results are in
        setLoaderState(false);
        // Check if errors are encountered.
        if (response?.data?.errorInfo) {
          alert(response.data.errorInfo.message);
          return '';
        }
        // return back success with refresh of employeedata
        props.onEditConfirmation(true, props.details.displayName);
      })
      // Disable loader in error
      .catch((error) => {
        setLoaderState(false);
        alert(error);
      });
  }


  return (
    <React.Fragment>
      <Button
        variant="primary"
        onClick={handleShow}
      >
        Edit
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form noValidate validated={validated} onSubmit={saveHandler}>
          <Form.Group className="mb-3" controlId="createUser">
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="displayName">Display Username</InputGroup.Text>
              <Form.Control
                type="text"
                value={props.details.displayName}
                disabled={true}
              />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="email">Email</InputGroup.Text>
              <Form.Control
                type="text"
                value={props.details.email}
                disabled={true}
              />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="firstName">First Name</InputGroup.Text>
              <Form.Control
                type="text"
                value={firstName}
                onChange={firstNameListener}
              />
            </InputGroup>
            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="lastName">Last Name</InputGroup.Text>
              <Form.Control
                type="text"
                value={lastName}
                onChange={lastNameListener}
              />
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="roles">Roles</InputGroup.Text>
              {
                roles.map((role, index) => (
                  <div key={`${role}-${index}`} style={{marginBottom:"3px"}}>
                    <Button key={`${role}-${index}`} variant='success' disabled>
                      {role}
                    </Button>
                    <X key={`x-${role}`} onClick={() => removeRoleHandler(index)}
                    />
                    &nbsp;&nbsp;
                  </div>
                ))
              }
              <DropdownButton key="role" title={"ADD ROLE"}>
              {
                userRolesList.map((val) => (
                  <Dropdown.Item key={val} onClick={() => {addRoleHandler(val)}}>
                    {val}
                  </Dropdown.Item>
                ))
              }
              </DropdownButton>
            </InputGroup>

            <InputGroup className="mb-1" size="sm">
            <InputGroup.Text id="phoneNumber">Phone Number</InputGroup.Text>
              <Form.Control
                type="text"
                pattern="\+63\d{10}"
                placeholder="+639xx xxx xxxx"
                value={phoneNumber}
                onChange={phoneNumberListener}
              />
              <Form.Control.Feedback type="invalid">
                Cellphone number must follow international format +63xxxxxxxxxx.
              </Form.Control.Feedback>
            </InputGroup>

            <br></br>
            <center>
              { loaderState
                ? <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                : <div>
                  <Button variant='primary' onClick={() => resetPassword(props.details.email)}>
                      Reset Password
                  </Button> &nbsp;
                  <Button variant="secondary" onClick={cancelHandler}>
                      Cancel
                  </Button> &nbsp;
                  <Button variant="primary" type="submit">
                      Save
                  </Button>
                </div>
              }
            </center>
          </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditUserModal


