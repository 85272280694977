import * as React from "react";
import MainHeader from "../components/navigation/MainHeader";
import { useNavigate } from "react-router-dom";
import classes from "./Home.module.css";
import { useEffect, useState } from "react";
import CustomLoader from "../components/ui-common/CustomLoader";
import firebase from 'firebase/compat/app';
import Footer from "../components/navigation/Footer";
import { useAuthContext } from "../components/context/useAuthContext";
// import { useAuthContext } from "../components/context/useAuthContext";


const Home = () => {

  const { user } = useAuthContext();
  const [messageList, setMessageList] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [roles, setRoles] = useState([]);

  const navigate = useNavigate();
  // const allowedRolesList = props.allowedRolesList;
  useEffect(() => {
    console.log("PO Input page use effect");
    if (user) {
      user.getIdTokenResult().then((tokenResult) => {
        if (tokenResult.claims?.roles) {
          const parsedUserRoles = JSON.parse(tokenResult.claims?.roles);
          // console.log(parsedUserRoles);
          // console.log(allowedRolesList);
          // if (!RolesChecker(parsedUserRoles, allowedRolesList)) {
          //   alert('Unauthorized access.');
          //   navigate('/');
          // }
          setDisplayName(tokenResult.claims?.name);
          setRoles(parsedUserRoles);
        }
      });
    } else {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            user.getIdTokenResult().then(function(data) {
              setRoles(JSON.parse(data.claims?.roles));
              setDisplayName(data.claims?.name);
            }
          );
        } else {
          alert('Unauthorized access.');
          navigate('/');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  // const { user } = useAuthContext();
  // useEffect(() => {
  //   // console.log(user);
  //   firebaseObserver.subscribe('authStateChanged', user => {
  //     if (!user) {
  //       alert('Unauthorized access.');
  //       navigate('/');
  //     }
  //   });
  //   firebaseObserver.subscribe('authOperatorClaim', isOperator => {
  //     if (isOperator) {
  //       navigate('/timeInOut');
  //     }
  //   });
  //   listAllBroadcasts();
  //   return () => {
  //     firebaseObserver.unsubscribe('authStateChanged');
  //     firebaseObserver.unsubscribe('authOperatorClaim');
  //   }
  // }, [navigate]);

  // async function listAllBroadcasts() {
  //   // Enable loader when request is being sent.
  //   setLoaderState(true);

  //   const broadcastsRef = collection(db, "broadcasts");
  //   const snapshot = await getDocs(broadcastsRef);
  //   const messageArray = [];
  //   snapshot.forEach((doc) => {
  //     messageArray.push({id: doc.id, ...doc.data()});
  //   });
  //   messageArray.sort((a, b) => DateTime.fromFormat(b.id, 'dd-MMM-yyyy') > DateTime.fromFormat(a.id, 'dd-MMM-yyyy') ? 1 : -1);
  //   setMessageList(messageArray);
  //   setLoaderState(false);
  // }

  return (
    <CustomLoader
    isActive={loaderState}
    >
      <div className={classes.overlayWrapper}>
        <MainHeader
          name={displayName}
          roles={roles}
        />
        <center>
        <h4 style={{marginTop:20}}>Welcome</h4>
        <ul className={classes.viewBox}>
        {
          messageList.map((val) => (
            <li key={val.id} style={{marginBottom:20}}>
              <b>{val.id}:</b><br></br>
              {val.message}
            </li>
          ))
        }
        </ul>
        </center>
        <br></br>
        <br></br>
        <Footer />
      </div>
    </CustomLoader>
  );
}

export default Home;
