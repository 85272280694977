import classes from './MainHeader.module.css';
import { useState, useEffect } from 'react';
import SideBar from './SideBar';


const MainHeader = (props) => {

  const [displayName, setDisplayName] = useState('');
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setDisplayName(props.name);
    if (props.roles) {
      setRoles(props.roles);
    }
  }, [props.roles, props.name]);

  return (
    <header className={classes.header} >
      <SideBar
        displayName={displayName}
        roles={roles}
      />
    </header>
  );
};

export default MainHeader;

