import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { functions } from "../../firebaseConfig";
import { httpsCallable } from "firebase/functions";

const ManageAdminModal = (props) => {

  const displayName = props.details.displayName;
  const email = props.details.email;

  const [show, setShow] = useState(false);
  // Loader state
  const [loaderState, setLoaderState] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function clickHandler(displayName, email, admin, superadmin, operator)  {
    // Enable loader when request is being sent.
    setLoaderState(true);
    const setUserAdmin = httpsCallable(functions, 'setUserAdmin');
    await setUserAdmin({
      displayName: displayName,
      email: email,
      admin: admin,
      superadmin: superadmin,
      operator: operator,
    })
    .then((response) => {
      setLoaderState(false);
      if (response.data.message) {
        props.onConfirmation(true);
        alert(response.data.message);
        setShow(false);
      } else {
        alert(response.data.errorInfo.message);
      }
    })
    .catch((error) => {
      setLoaderState(false);
      alert(error);
    });
  };

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        {props.displayName}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Admin Privileges</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Display Name: {displayName} <br></br>
          Email: {email}
        </Modal.Body>
        <Modal.Footer>
          { loaderState ?
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          />
          :
          <div style={{display:"flexbox", textAlign:"left"}}>
          <Button
            variant='primary'
            type='button'
            style={{width:"180px", margin:"5px"}}
            onClick={() => clickHandler(displayName, email, true, true, false)}
          >
            Make Super Admin
          </Button>
          <Button
            variant='primary'
            type='button'
            style={{width:"180px", margin:"5px"}}
            onClick={() => clickHandler(displayName, email, true, false, false)}
          >
            Make Admin
          </Button>
          <br></br>
          <Button
            variant='primary'
            type='button'
            style={{width:"180px", margin:"5px"}}
            onClick={() => clickHandler(displayName, email, true, false, false)}
          >
            Revoke Super Admin
          </Button>
          <Button
            variant='primary'
            type='button'
            style={{width:"180px", margin:"5px"}}
            onClick={() => clickHandler(displayName, email, false, false, false)}
          >
            Revoke Admin
          </Button>
          <br></br>
          <Button
            variant='primary'
            type='button'
            style={{width:"180px", margin:"5px"}}
            onClick={() => clickHandler(displayName, email, false, false, true)}
          >
            Make Operator
          </Button>
          <Button
            variant='primary'
            type='button'
            style={{width:"180px", margin:"5px"}}
            onClick={() => clickHandler(displayName, email, false, false, false)}
          >
            Revoke Operator
          </Button>
          </div> }
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ManageAdminModal


